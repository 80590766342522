import { Button, Table, Space, Tooltip, message } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { CopyOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { parseFilter } from '@/utils/filter';
import { ApiKey, RawApiKey } from '@/interfaces/apiKey.interface';
import { FilterByRegex, FilterByStatus, FilterByIcon } from '../utils/CommonFilters';

export interface ApiKeysTableProps {
  data?: RawApiKey[];
  total?: number;
  current?: number;
  loading?: boolean;
  onFilter?: (value: any) => any;
  onEdit?: (data: ApiKey) => void;
  onView?: (data: ApiKey) => void;
  onDelete?: (id: string) => void;
}

const ApiKeysTable = ({
  data,
  total,
  loading,
  onFilter,
  onDelete,
  onEdit,
}: ApiKeysTableProps) => {
  const { t } = useTranslation();
  const [messageApi, contextHolder] = message.useMessage();

  const copyToClipboard = async (text: string): Promise<boolean> => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text).then(() => true);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const handleCopy = (text: string) => {
    copyToClipboard(text)
      .then(() => {
        messageApi.info(t('apiKeys.copy'));
      })
      .catch((error) => {
        console.error('[copyToClipboard] Error to copy: ', error);
      });
  };

  const Columns: ColumnsType<RawApiKey> = [
    {
      title: t('users.user'),
      dataIndex: 'user_id',
      key: 'user_id',
      render: (_: string, record: RawApiKey) => <span>{record.user?.full_name}</span>,
    },
    {
      title: t('users.role'),
      dataIndex: 'rol_id',
      key: 'rol_id',
      render: (_: string, record: RawApiKey) => <span>{record.rol?.name}</span>,
    },
    {
      title: t('apiKeys.column'),
      dataIndex: 'api_key',
      key: 'api_key',
      render: (key: string) => (
        <Tooltip title={key}>
          <span>
            {key.substring(0, 6)} ... {key.substring(key.length - 6, key.length)}
          </span>
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={() => handleCopy(key)}
          ></Button>
        </Tooltip>
      ),
    },
    {
      title: t('apiKeys.projectName'),
      dataIndex: 'project_name',
      key: 'project_name',
      filterDropdown: FilterByRegex,
      filterIcon: FilterByIcon,
    },
    {
      title: t('general.createdAt'),
      dataIndex: 'created_at',
      key: 'created_at',
      render: (created_at: string) =>
        created_at && new Date(created_at).toLocaleDateString(),
    },
    {
      title: t('general.status'),
      dataIndex: 'status',
      filters: [
        {
          text: t('general.active'),
          value: '1',
        },
        {
          text: t('general.inactive'),
          value: '0',
        },
      ],
      filterDropdown: FilterByStatus([
        {
          label: t('general.active'),
          value: '1',
        },
        {
          label: t('general.inactive'),
          value: '0',
        },
      ]),
      key: 'status',
      render: (status: number) =>
        status === 1 ? t('general.active') : t('general.inactive'),
    },
    {
      title: t('general.actions'),
      key: '_id',
      render: (_: string, record: RawApiKey) => (
        <Space size="middle">
          <Button type="link" onClick={() => onEdit?.(record)}>
            {t('general.edit')}
          </Button>
          <Button type="link" onClick={() => onDelete?.(record._id)}>
            {t('general.delete')}
          </Button>
        </Space>
      ),
    },
  ];

  const handleOnChange = (
    pagination: any = {},
    filters: any = {},
    sorter: any,
    { action }: { action: string },
  ) => {
    if (onFilter) {
      onFilter((value: Record<string, string>) => ({
        ...parseFilter(filters, value),
        $skip: pagination.current ? (pagination.current - 1) * 10 : 0,
      }));
    }
  };

  return (
    <>
      {contextHolder}
      <Table
        className="custom-table"
        dataSource={data}
        columns={Columns}
        loading={loading}
        onChange={handleOnChange}
        rowKey={(e) => e._id}
        pagination={{
          total,
          defaultPageSize: 10,
          showTotal: (total) => t('general.total', { total }),
        }}
      />
    </>
  );
};

export default ApiKeysTable;
