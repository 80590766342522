import React, { useEffect } from 'react';
import { Modal, Form, message } from 'antd';
import ApiKeyForm from '../forms/ApiKeyForm';
// Hooks
import { useTranslation } from 'react-i18next';
// Interfaces
import { ApiKey } from '@/interfaces/apiKey.interface';
// Services
import APIService from '@/services/API';

export interface ApiKeysModalProps {
  visible: boolean;
  edit?: boolean;
  data?: Record<string, string | number>;
  onCancel?: () => void;
  onOk?: () => void;
}

const ApiKeysModal: React.FC<ApiKeysModalProps> = ({
  visible,
  edit,
  onCancel,
  onOk,
  data,
}) => {
  const [formRef] = Form.useForm<ApiKey>();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    if (edit && data) {
      formRef.setFieldsValue(data);
    }
  }, [edit, data, formRef]);

  const handleOnSubmit = async (values: ApiKey) => {
    setLoading(true);
    const response = data?._id
      ? await APIService.updateApiKey(data?._id as string, values)
      : await APIService.createApiKey(values);
    if (response.ok) {
      if (onOk) {
        onOk();
      }
      formRef.resetFields();
      if (edit) {
        message.success(t('apiKeys.updateSuccess'));
      } else {
        message.success(t('apiKeys.createSuccess'));
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      centered
      open={visible}
      title={edit ? t('apiKeys.edit') : t('apiKeys.create')}
      onOk={() => formRef.submit()}
      okText={edit ? t('general.update') : t('general.create')}
      width={700}
      onCancel={() => {
        onCancel?.();
        formRef.resetFields();
      }}
      okButtonProps={{ loading }}
    >
      <ApiKeyForm
        formRef={formRef}
        edit={edit}
        onSubmit={handleOnSubmit}
        initialValues={data}
      />
    </Modal>
  );
};

export default ApiKeysModal;
