import React, { useEffect } from 'react';
import { Form, FormInstance, Input, Select, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import APIService from '@/services/API';
import { ApiKey } from '@/interfaces/apiKey.interface';
import { useQuery } from 'react-query';

export interface ApiKeyFormProps {
  formRef: FormInstance<ApiKey>;
  initialValues?: Record<string, string | number>;
  edit?: boolean;
  onSubmit?: (values: ApiKey) => Promise<void>;
}
let timeout: NodeJS.Timeout;

const ApiKeyForm: React.FC<ApiKeyFormProps> = ({
  onSubmit,
  edit = true,
  formRef,
  initialValues,
}) => {
  const { t } = useTranslation();
  const [userParams, setUserParams] = React.useState<Record<string, string>>({
    $skip: '0',
    $limit: '10',
  });
  const { isLoading, data } = useQuery(['roles'], () => APIService.getRoles());
  const { data: users, isLoading: loadingUsers } = useQuery(['users', userParams], () =>
    APIService.getUsers(userParams),
  );
  useEffect(() => {
    if (edit && initialValues?.user_id) {
      setUserParams((prev) => ({
        ...prev,
        _id: initialValues.user_id.toString(),
      }));
    } else {
      setUserParams({ $skip: '0', $limit: '10' });
    }
  }, [edit, initialValues]);

  return (
    <Form
      form={formRef}
      name="api-key-form"
      layout="vertical"
      autoComplete="off"
      onFinish={onSubmit}
    >
      <Row gutter={[10, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={t('users.user')}
            name="user_id"
            shouldUpdate
            rules={[{ required: true, type: 'string', whitespace: true }]}
          >
            <Select
              loading={loadingUsers}
              notFoundContent={false}
              showSearch
              optionFilterProp="children"
              filterOption={false}
              onSearch={(value) => {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                  setUserParams({
                    $skip: '0',
                    $limit: '10',
                    'full_name[$regex]': value,
                    'full_name[$options]': 'i',
                  });
                }, 500);
              }}
              options={users?.data.map((user) => {
                return {
                  label: user.full_name,
                  value: user._id,
                };
              })}
            />
          </Form.Item>
        </Col>

        <Col span={24} md={12}>
          <Form.Item label={t('users.role')} name="rol_id">
            <Select
              loading={isLoading}
              style={{ width: '100%' }}
              options={data?.data.map((role) => ({
                label: role.name,
                value: role._id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>

      {edit && (
        <Row>
          <Col span={24}>
            <Form.Item label={t('apiKeys.column')}>
              <Input disabled value={formRef.getFieldValue('api_key')} />
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row gutter={[10, 10]}>
        <Col span={24} md={12}>
          <Form.Item
            label={t('apiKeys.projectName')}
            name="project_name"
            rules={[
              { required: true, type: 'string', min: 5, max: 30, whitespace: true },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        {edit && (
          <Col span={24} md={12}>
            <Form.Item
              label={t('general.status')}
              name="status"
              initialValue={1}
              rules={[{ required: true, message: <>{t('users.required')}</> }]}
            >
              <Select
                style={{ width: '100%' }}
                options={[
                  {
                    label: t('general.active'),
                    value: 1,
                  },
                  {
                    label: t('general.inactive'),
                    value: 0,
                  },
                ]}
              />
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
};

export default ApiKeyForm;
